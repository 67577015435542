.home {
    h3 {
        margin: 0;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }

    ul {
        margin: 0;
        padding: 0 1.5rem;
        list-style: none;
        text-indent: -1.5rem;
    }
}
